/*!
 * Start Bootstrap - Creative v7.0.2 (https://startbootstrap.com/theme/creative)
 * Copyright 2013-2021 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-creative/blob/master/LICENSE)
 */
//
// Scripts
//

window.addEventListener("DOMContentLoaded", (event) => {
  var navbarShrink = function () {
    const navbarCollapsible = document.body.querySelector("#mainNav");
    if (!navbarCollapsible) {
      return;
    }
    if (window.scrollY === 0) {
      navbarCollapsible.classList.remove("navbar-shrink");
    } else {
      navbarCollapsible.classList.add("navbar-shrink");
    }
  };

  navbarShrink();

  document.addEventListener("scroll", navbarShrink);

  const mainNav = document.body.querySelector("#mainNav");
  if (mainNav) {
    new bootstrap.ScrollSpy(document.body, {
      target: "#mainNav",
      offset: 74,
    });
  }

  const navbarToggler = document.body.querySelector(".navbar-toggler");
  const responsiveNavItems = [].slice.call(
    document.querySelectorAll("#navbarResponsive .nav-link")
  );
  responsiveNavItems.map(function (responsiveNavItem) {
    responsiveNavItem.addEventListener("click", () => {
      if (window.getComputedStyle(navbarToggler).display !== "none") {
        navbarToggler.click();
      }
    });
  });

  new SimpleLightbox({
    elements: "#portfolio a.portfolio-box",
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    },
    {
      threshold: 0.1,
    }
  );

  const aboutElements = document.querySelectorAll(
    "#about img, #about h2, #about hr, #about p, #about .two-columns"
  );
  aboutElements.forEach((el) => {
    el.classList.add("fade-in-scroll");
    observer.observe(el);
  });

  const skillsColumns = document.querySelectorAll(
    "#skills .two-columns .text-column"
  );
  skillsColumns[0].classList.add("skills-slide-left");
  skillsColumns[1].classList.add("skills-slide-right");
  observer.observe(skillsColumns[0]);
  observer.observe(skillsColumns[1]);

  // Services section animations
  const serviceCards = document.querySelectorAll(".services-card");
  serviceCards.forEach((card, index) => {
    card.style.transitionDelay = `${index * 0.3}s`;
    observer.observe(card);
  });

  // Add portfolio cards animation
  const portfolioCards = document.querySelectorAll(".portfolio-card");
  portfolioCards.forEach((card, index) => {
    card.classList.add("fade-in-scroll");
    card.style.transitionDelay = `${index * 0.3}s`;
    observer.observe(card);
  });
});

// Handle active nav links based on scroll position
window.addEventListener("scroll", () => {
  let fromTop = window.scrollY;

  document.querySelectorAll("section[id]").forEach((section) => {
    const sectionTop = section.offsetTop - 100;
    const sectionHeight = section.offsetHeight;
    const sectionId = section.getAttribute("id");
    const navigationLink = document.querySelector(`a[href="#${sectionId}"]`);

    if (fromTop >= sectionTop && fromTop < sectionTop + sectionHeight) {
      navigationLink?.classList.add("active");
    } else {
      navigationLink?.classList.remove("active");
    }
  });

  // Special case for #page-top
  const topLink = document.querySelector('a[href="#page-top"]');
  if (fromTop < 100) {
    topLink?.classList.add("active");
  } else {
    topLink?.classList.remove("active");
  }
});
